import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import TournamentGroup from 'src/components/tournament-group/tournament-group'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'

const TournamentGroupPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments/groups">
      <TournamentGroupRoute path="/:groupId" />
    </Router>
  )
}

interface TournamentGroupProps extends RouteComponentProps {
  groupId?: string
}

const TournamentGroupRoute: React.FC<TournamentGroupProps> = ({ groupId }) => {
  return (
    <Layout>
        <SEO title="Tournament Group" />
        <TournamentGroup groupId={groupId} />
    </Layout>
  )
}

export default TournamentGroupPage
