import gql from 'graphql-tag'

export const TOURNAMENT_POOL_SELECTIONS = gql`
  query TournamentPoolSelections($poolId: UUID!, $date: Date!, $gender: Gender!) {
    tournamentPoolSelections(tournamentPoolId: $poolId, rankingDateTime: $date, gender: $gender) {
      ageDivision
      eventId
      name
      priorityForSorting
      rank
      selectionInstruction
      tournamentId
      tournamentName
      uaid
    }
  }
`

export const PROCESS_TOURNAMENT_POOL_SELECTIONS = gql`
  mutation ProcessTournamentPoolSelections($poolId: UUID!, $date: Date!, $gender: Gender!) {
    processTournamentPoolSelections(
      tournamentPoolId: $poolId
      rankingDateTime: $date
      gender: $gender
    ) {
      id
    }
  }
`

export const RESET_TOURNAMENT_POOL_SELECTIONS = gql`
  mutation ResetTournamentPoolSelections($poolId: UUID!) {
    resetTournamentPoolSelections(tournamentPoolId: $poolId)
  }
`
