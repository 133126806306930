import React, { ReactNode } from 'react'
import Panel from '../panel/panel'
import Spinner from '../spinner/spinner'
import { H3 } from '../typography/typography'
import Button from '../button/button'
import * as styles from './info-panel.module.less'
import { IconProps } from '../icon/icon'
import { LevelColour } from 'src/hooks/level-colour'

export interface Props {
  loading?: boolean
  title?: string
  subtitle?: ReactNode
  image?: string
  actionButtons?: {
    icon?: IconProps['name']
    title: string
    onClick: () => void
    loading?: boolean
    level: LevelColour
  }[]
  customEndContent?: ReactNode
}

const InfoPanel: React.FC<Props> = ({
  loading,
  title,
  subtitle,
  image,
  actionButtons,
  children,
  customEndContent
}) => {
  return (
    <Panel>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.infoPanel}>
          {image && <img src={image} className={styles.image}></img>}
          <div className={styles.content}>
            <header className={styles.topContent}>
              <div className={styles.header}>
                <H3>{title}</H3>
                {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
              </div>
              {actionButtons &&
                actionButtons.map(button => {
                  return (
                    <Button
                      key={button.title}
                      level={button.level}
                      onClick={button.onClick}
                      loading={button.loading}
                      spacing={{ margins: { xs: 'horizontal' } }}
                      icon={button.icon}
                    >
                      {button.title}
                    </Button>
                  )
                })}
                {customEndContent}
            </header>
            {children && (
              <>
                <hr className={styles.hr} />
                <footer className={styles.bottomContent}>{children}</footer>
              </>
            )}
          </div>
        </div>
      )}
    </Panel>
  )
}

interface NuggetProps {
  title: string
}

export const InfoNugget: React.FC<NuggetProps> = ({ title, children }) => {
  return (
    <div className={styles.nugget}>
      <div className={styles.nuggetTitle}>{title}</div>
      <div className={styles.nuggetBody}>{children}</div>
    </div>
  )
}

export default InfoPanel
