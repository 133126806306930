// extracted by mini-css-extract-plugin
export var body = "floating-notification-module--body--tYlE5";
export var bodyBase = "floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodyLarge = "floating-notification-module--body-large--ozvRE floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodyLargeBold = "floating-notification-module--body-large-bold---j63u floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodyRegular = "floating-notification-module--body-regular--xxCBK floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodyRegularBold = "floating-notification-module--body-regular-bold--bysHo floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodySmall = "floating-notification-module--body-small--c5LF4 floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var bodySmallBold = "floating-notification-module--body-small-bold---IOXL floating-notification-module--body-base--E7jXS floating-notification-module--site-font--CLsMw";
export var borderTop = "floating-notification-module--border-top--CpX-U";
export var breakWordContainer = "floating-notification-module--break-word-container--Fdxig";
export var buttonIconBase = "floating-notification-module--button-icon-base--l5DEg";
export var clickLink = "floating-notification-module--click-link--0swU1";
export var close = "floating-notification-module--close--Dz3FG";
export var downloadBody = "floating-notification-module--download-body--rHJlk floating-notification-module--body--tYlE5";
export var downloadClose = "floating-notification-module--download-close---ooU0 floating-notification-module--close--Dz3FG";
export var downloadFloating = "floating-notification-module--download-floating--nEBT6 floating-notification-module--floating--F3el0";
export var downloadMessage = "floating-notification-module--download-message--sS-GH floating-notification-module--success-message--IXaYN floating-notification-module--message--WFDLI";
export var downloadedBody = "floating-notification-module--downloaded-body--eylrz floating-notification-module--body--tYlE5";
export var downloadedClose = "floating-notification-module--downloaded-close--CWCWc floating-notification-module--success-close--e2hjc floating-notification-module--close--Dz3FG";
export var downloadedFloating = "floating-notification-module--downloaded-floating--qmm5N floating-notification-module--download-floating--nEBT6 floating-notification-module--floating--F3el0";
export var downloadedMessage = "floating-notification-module--downloaded-message--I4t8m floating-notification-module--success-message--IXaYN floating-notification-module--message--WFDLI";
export var dropdownBase = "floating-notification-module--dropdown-base--S2RS6";
export var dropdownSelectBase = "floating-notification-module--dropdown-select-base--3dwbw floating-notification-module--text-input--sOpRN";
export var flexCol = "floating-notification-module--flex-col--pYwGH";
export var floating = "floating-notification-module--floating--F3el0";
export var formErrorMessage = "floating-notification-module--form-error-message--L8C6R";
export var h3 = "floating-notification-module--h3--5nQyo";
export var h4 = "floating-notification-module--h4--KwcSz";
export var hoverLink = "floating-notification-module--hover-link--glyHY";
export var hoverRow = "floating-notification-module--hover-row--iTptu";
export var icon = "floating-notification-module--icon--4sGoy";
export var lgSize = "floating-notification-module--lg-size--jJNi8";
export var membershipContainer = "floating-notification-module--membership-container--AuS8n floating-notification-module--flex-col--pYwGH floating-notification-module--primary-border--OxUYa";
export var membershipHeader = "floating-notification-module--membership-header--RmxI-";
export var membershipHeading = "floating-notification-module--membership-heading--4y4K+";
export var membershipLabel = "floating-notification-module--membership-label--q0vdb";
export var message = "floating-notification-module--message--WFDLI";
export var moreFiltersBorderClass = "floating-notification-module--more-filters-border-class--67KrU";
export var neutralBody = "floating-notification-module--neutral-body--gs+a4 floating-notification-module--body--tYlE5";
export var neutralClose = "floating-notification-module--neutral-close--r3HTN floating-notification-module--close--Dz3FG";
export var neutralFloating = "floating-notification-module--neutral-floating--xBI3h floating-notification-module--floating--F3el0";
export var neutralMessage = "floating-notification-module--neutral-message--xCnk5 floating-notification-module--message--WFDLI";
export var pageBg = "floating-notification-module--page-bg--TeSdk";
export var pointer = "floating-notification-module--pointer--TI2Em";
export var primaryBorder = "floating-notification-module--primary-border--OxUYa";
export var shadowBoxLight = "floating-notification-module--shadow-box-light--EAFhc";
export var siteFont = "floating-notification-module--site-font--CLsMw";
export var slideDownAndFade = "floating-notification-module--slideDownAndFade--OKbkO";
export var slideLeftAndFade = "floating-notification-module--slideLeftAndFade--wRpdu";
export var slideRightAndFade = "floating-notification-module--slideRightAndFade--oiLRT";
export var slideUpAndFade = "floating-notification-module--slideUpAndFade--JisI0";
export var smSize = "floating-notification-module--sm-size--xq2WK";
export var statusDecoration = "floating-notification-module--status-decoration--VisOz";
export var successBody = "floating-notification-module--success-body--JGfER floating-notification-module--body--tYlE5";
export var successClose = "floating-notification-module--success-close--e2hjc floating-notification-module--close--Dz3FG";
export var successFloating = "floating-notification-module--success-floating--xeSYJ floating-notification-module--floating--F3el0";
export var successMessage = "floating-notification-module--success-message--IXaYN floating-notification-module--message--WFDLI";
export var textInput = "floating-notification-module--text-input--sOpRN";
export var textInverted = "floating-notification-module--text-inverted--SXz8Q";
export var textMediumDark = "floating-notification-module--text-medium-dark--2AoYd";
export var tooltipFont = "floating-notification-module--tooltipFont--NVmAI";
export var unstyledButton = "floating-notification-module--unstyled-button--rShji";