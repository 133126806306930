import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GENERATE_CSV_REPORT, CHECK_UPLOAD_STATE } from './generate-report-queries'
import { meshGatewayClient } from 'src/apollo/client'
import { getEnvConfig } from 'src/config/config'
import { useOrgId } from 'src/apollo/local-state'
import { ReportUploadState, GenerateReportProps } from './generate-report'

export type ReportState = 'COMPLETE' | 'PENDING' | 'PROCCESSING' | 'ERROR'
export interface AutomaticDownloadProps extends GenerateReportProps {
  downloadable: boolean
  setDownloadable: (val: boolean) => void
  onGenerateOrUploadError?: (error) => void
}

const AutomaticDownload: React.FC<AutomaticDownloadProps> = ({
  generateButtonTitle,
  reportQuery,
  reportQueryEndpoint,
  outputFormat = 'CSV',
  buttonProps = {},
  paginator,
  onReportUploadStateChange,
  reportUploadState: controlledReportState,
  reportQueryVariables,
  csvTransforms,
  downloadable,
  setDownloadable,
  filename,
  csvFormatOptions,
  onGenerateOrUploadError
}) => {
  const [internalReportState, setInternalReportState] = useState<ReportUploadState>('none')
  const reportState = controlledReportState ?? internalReportState

  const [uploadState, setUploadState] = useState<ReportState>()
  const [uploaded, setUploaded] = useState()

  const queryVariablesJSON = useMemo(() => {
    try {
      if (reportQueryVariables) return JSON.stringify(reportQueryVariables)
    } catch {
      return undefined
    }
  }, [reportQueryVariables])

  const [generateCSV, { data }] = useMutation(GENERATE_CSV_REPORT, {
    client: meshGatewayClient,
    variables: {
      query: reportQuery,
      endpoint: reportQueryEndpoint,
      paginator,
      outputFormat,
      queryVariablesJSON,
      csvTransforms,
      csvFormatOptions
    }
  })

  const [downloadLink, setDownloadLink] = useState<string | undefined>()

  const providerId = useOrgId()

  useEffect(() => {
    if (providerId && data?.addReport?.url) {
      const filenameParam = filename
        ? `&originalname=${filename}.${outputFormat.toLowerCase()}`
        : ''

      // Swift proxy url ends with "?", in which case remove leading "/" from report route
      const gatewayUrl = getEnvConfig().GATEWAY_BASE_URL
      let reportRoute: string = data?.addReport?.url ?? ''
      if (gatewayUrl.endsWith('?')) reportRoute = reportRoute.replace(/^\/+/, '')

      setDownloadLink(`${gatewayUrl}${reportRoute}?providerId=${providerId}${filenameParam}`)
    }
  }, [data])

  const updateReportState = useCallback(
    (uploadState: ReportUploadState) => {
      const uploadInfo = uploadState === 'downloadable' ? { downloadUrl: downloadLink } : undefined
      onReportUploadStateChange?.(uploadState, uploadInfo)
      setInternalReportState(uploadState)
    },
    [setInternalReportState, onReportUploadStateChange, downloadLink]
  )

  const [awaitUpload, awaitUploadResult] = useLazyQuery(CHECK_UPLOAD_STATE, {
    client: meshGatewayClient,
    pollInterval: 2000
  })

  const generateReport = useCallback(async () => {
    updateReportState('processing')
    try {
      const reportId = (await generateCSV()).data?.addReport?.id
      if (reportId) {
        awaitUpload({ variables: { reportId } })
      } else {
        updateReportState('none')
      }
    } catch (err) {
      updateReportState('none')
      onGenerateOrUploadError?.(err)
    }
  }, [generateCSV, awaitUpload, updateReportState, onGenerateOrUploadError])

  useEffect(() => {
    setUploadState(awaitUploadResult.data?.reportMetadata?.status?.state)
    setUploaded(awaitUploadResult.data?.reportMetadata?.uploaded)

    if (awaitUploadResult.data?.reportMetadata?.status?.state === 'COMPLETE') {
      setDownloadable(true)
    }
  }, [awaitUploadResult, updateReportState])

  useEffect(() => {
    if (uploaded || uploadState === 'ERROR' || uploadState === 'COMPLETE') {
      awaitUploadResult?.stopPolling?.()
      updateReportState(uploaded ? 'downloadable' : 'none')
    }
  }, [uploadState, uploaded])

  const { t } = useTranslation()

  useEffect(() => {
    if (
      reportState === 'downloadable' &&
      typeof window !== 'undefined' &&
      downloadLink &&
      uploadState !== 'ERROR' &&
      downloadable
    ) {
      window.location.href = downloadLink
      setDownloadable(false)
      setUploadState(undefined)
      setUploaded(undefined)
    }
  }, [reportState, downloadLink, uploaded, uploadState, downloadable])

  return (
    <Button {...buttonProps} loading={reportState === 'processing'} onClick={generateReport}>
      {generateButtonTitle ?? t('generate report', { type: outputFormat })}
    </Button>
  )
}

export default AutomaticDownload
