import { useMutation, useQuery } from '@apollo/client'
import { BodyLarge, BodyRegular, BodyRegularBold } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import moment from 'moment-timezone'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tournamentsClient } from 'src/apollo/client'
import { useOrgId } from 'src/apollo/local-state'
import {
  AddTournamentsToPool,
  AddTournamentsToPoolVariables
} from 'src/graphql-types/AddTournamentsToPool'
import {
  DeleteTournamentPool,
  DeleteTournamentPoolVariables
} from 'src/graphql-types/DeleteTournamentPool'
import {
  GetTournamentPool,
  GetTournamentPoolVariables,
  GetTournamentPool_tournamentPool as TournamentPool,
  GetTournamentPool_tournamentPool_registrationDates as RegistrationDates
} from 'src/graphql-types/GetTournamentPool'
import { Operator, PropertyFilterInput } from 'src/graphql-types/globalTournamentTypes'
import {
  GroupTournamentOptions,
  GroupTournamentOptionsVariables,
  GroupTournamentOptions_paginatedTournaments_items as Tournament,
  GroupTournamentOptions_paginatedTournaments_items_registrationRestrictions as RegistrationRestrictions
} from 'src/graphql-types/GroupTournamentOptions'
import {
  RemoveTournamentsFromPool,
  RemoveTournamentsFromPoolVariables
} from 'src/graphql-types/RemoveTournamentsFromPool'
import AdminTable from '../admin-table/admin-table'
import Alert from '../alert/alert'
import APIErrorMessage from '../api-error-message/api-error-message'
import Button from '../button/button'
import { Checkbox } from '../formik-fields/formik-fields'
import InfoPanel, { InfoNugget } from '../info-panel/info-panel'
import ButtonModal from '../modal/modal'
import PageHeader from '../page-header/page-header'
import Panel from '../panel/panel'
import { useControlledQuery } from '../table-controls/table-controls'
import {
  DownloadCSVModal,
  ProcessSelectionsModal,
  ResetSelectionsModal
} from '../tournament-group-selections/tournament-group-selections'
import {
  clearTPs,
  DELETE_TOURNAMENTS_POOL
} from '../tournament-groups-overview/tournament-groups-overview-queries'
import { PageMaxWidth } from '../util-components/util-components'
import {
  ADD_TOURNAMENTS_TO_POOL,
  GET_ADD_GROUP_TOURNAMENT_OPTIONS,
  GET_TOURNAMENT_GROUP_DETAIL,
  REMOVE_TOURNAMENTS_FROM_POOL
} from './tournament-group-queries'
import * as styles from './tournament-group.module.less'

interface Props {
  groupId?: string
}

const TournamentGroup: React.FC<Props> = ({ groupId }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [removeGroupModal, setRemoveGroupModal] = useState(false)
  const [downloadCSVModal, setDownloadCSVModal] = useState(false)
  const [processSelectionsModal, setProcessSelectionsModal] = useState(false)
  const [resetSelectionsModal, setResetSelectionsModal] = useState(false)

  const [tourn, setTourn] = useState<Tournament>()
  const orgId = useOrgId()
  const { data, loading, refetch } = useQuery<GetTournamentPool, GetTournamentPoolVariables>(
    GET_TOURNAMENT_GROUP_DETAIL,
    {
      client: tournamentsClient,
      notifyOnNetworkStatusChange: true,
      variables: { orgId, poolId: groupId ?? '' }
    }
  )
  const pool = data?.tournamentPool

  const getTotalItems = useCallback(
    (d: GroupTournamentOptions) => d.paginatedTournaments.totalItems,
    []
  )

  const queryFilters = useMemo<PropertyFilterInput[]>(() => {
    return [
      { property: 'sanctionStatus', values: [], operator: Operator.NOT_NULL },
      { property: 'timings.startDate', values: [pool?.toDate ?? ''], operator: Operator.LT },
      { property: 'timings.startDate', values: [pool?.fromDate ?? ''], operator: Operator.GTE },
      { property: 'restrictedToLevelId', values: [pool?.levelId ?? ''], operator: Operator.EQUALS }
    ]
  }, [pool])

  const { refetch: refetchTournaments } = useControlledQuery<
    GroupTournamentOptions,
    GroupTournamentOptionsVariables,
    Tournament
  >(GET_ADD_GROUP_TOURNAMENT_OPTIONS, {
    client: tournamentsClient,
    variables: { orgId, filters: queryFilters },
    getTotalItems
  })

  const [removeTournamentsFromPool, { loading: removing, error }] = useMutation<
    RemoveTournamentsFromPool,
    RemoveTournamentsFromPoolVariables
  >(REMOVE_TOURNAMENTS_FROM_POOL, { client: tournamentsClient })

  const openRemoveModal = tourn => {
    setShowModal(true)
    setTourn(tourn)
  }

  const handleRemove = async () => {
    const res = await removeTournamentsFromPool({
      variables: { tournamentIds: [tourn?.id], tournamentPoolId: groupId },
      update: () => {
        refetch()
        refetchTournaments()
        clearTPs(tournamentsClient.cache)
      }
    })
    if (res) setShowModal(false)
  }

  const [removeTournamentPool, { loading: removingTp, error: removeError }] = useMutation<
    DeleteTournamentPool,
    DeleteTournamentPoolVariables
  >(DELETE_TOURNAMENTS_POOL, { client: tournamentsClient })

  const handleRemoveGroup = async () => {
    const res = await removeTournamentPool({
      variables: { tournamentPoolId: pool?.id },
      update: clearTPs
    })
    if (res) {
      setRemoveGroupModal(false)
      navigate('/tournaments/groups')
    }
  }

  return (
    <PageMaxWidth>
      <PageHeader title={t('tournament group')} />
      <InfoPanel
        title={pool?.name}
        loading={loading}
        subtitle={t('tournament group dates', {
          dates: { start: moment(pool?.fromDate), end: moment(pool?.toDate) }
        })}
        actionButtons={[
          {
            title: t('reset selections'),
            onClick: () => setResetSelectionsModal(true),
            level: 'tertiary'
          },
          {
            title: t('process selections'),
            onClick: () => setProcessSelectionsModal(true),
            level: 'tertiary'
          },
          {
            title: t('download csv'),
            onClick: () => setDownloadCSVModal(true),
            level: 'primary'
          },
          {
            title: t('delete'),
            onClick: () => setRemoveGroupModal(true),
            level: 'warningOutlined'
          }
        ]}
      >
        <InfoNugget title={t('max tournaments per player')}>
          {pool?.maxTournamentsPerPlayer || t('n/a')}
        </InfoNugget>
        <InfoNugget title={t('max divisions per player per tournaments')}>
          {pool?.maxDivisionsPerPlayerPerTournament || t('n/a')}
        </InfoNugget>
        <InfoNugget title={t('multiple division registrations as tournament count')}>
          {pool?.countDivisionsAsTournaments ? t('yes') : t('no')}
        </InfoNugget>
        <InfoNugget title={t('priority player selection')}>
          {pool?.priorityPlayerSelection ? t('yes') : t('no')}
        </InfoNugget>
        <InfoNugget title={t('website promotion')}>
          {pool?.websitePromotion ? t('yes') : t('no')}
        </InfoNugget>
      </InfoPanel>
      <ButtonModal
        actionButtons={[
          {
            id: 'remove',
            content: t('delete'),
            disableAutoClose: true,
            props: {
              type: 'submit',
              loading: removingTp,
              onClick: handleRemoveGroup,
              level: 'warning'
            }
          }
        ]}
        show={removeGroupModal}
        cancelButton
        title={t('remove tournament group')}
        openButton={{ hidden: true }}
        onVisibilityChange={setRemoveGroupModal}
      >
        {error && <div className={styles.error}>{error.message}</div>}
        <RemoveTournamentPoolModal
          isEmpty={pool?.tournaments?.length === 0}
          registrationDates={pool?.registrationDates}
        />
      </ButtonModal>

      <ProcessSelectionsModal
        setShow={setProcessSelectionsModal}
        showModal={processSelectionsModal}
        groupId={groupId}
      />

      <DownloadCSVModal
        setShow={setDownloadCSVModal}
        showModal={downloadCSVModal}
        groupId={groupId}
      />

      <ResetSelectionsModal
        setShow={setResetSelectionsModal}
        showModal={resetSelectionsModal}
        groupId={groupId}
      />

      <Panel
        title={t('tournaments')}
        headerEndContent={!!pool && <AddTournamentModal pool={pool} refetchPool={refetch} />}
      >
        <AdminTable
          data={data?.tournamentPool.tournaments}
          loading={loading}
          // onRowClick={r => navigate(`/tournaments/${r.id}`)}
          columns={[
            { key: 'name', title: t('name'), getValue: x => x.name },
            { key: 'level', title: t('level'), getValue: x => x.level?.name },
            {
              key: 'start',
              title: t('start date'),
              getValue: x => t('group tournament date', { date: moment(x.timings.startDate) })
            },
            {
              key: 'end',
              title: t('end date'),
              getValue: x => t('group tournament date', { date: moment(x.timings.endDate) })
            },
            {
              key: 'deadline',
              title: t('registration deadline'),
              getValue: x =>
                t('registration date', {
                  date: moment(x.registrationRestrictions.entriesCloseDateTime).tz(
                    x.registrationRestrictions.timeZone
                  ),
                  tz: moment.tz(x.registrationRestrictions.timeZone)
                })
            },
            { key: 'city', title: t('city'), getValue: x => x.primaryLocation.town },
            { key: 'state', title: t('state'), getValue: x => x.primaryLocation.county },
            {
              key: 'director',
              title: t('tournament director'),
              getValue: x => `${x.director?.firstName ?? ''} ${x.director?.lastName ?? ''}`
            },
            {
              key: 'delete',
              title: ' ',
              getValue: x => (
                <Grid container alignContent="flex-end">
                  <Button level="link" onClick={() => navigate(`/tournaments/${x.id}`)}>
                    {t('view')}
                  </Button>
                  <Button level="link" onClick={e => openRemoveModal(x)}>
                    {t('remove')}
                  </Button>
                </Grid>
              )
            }
          ]}
        />
        <ButtonModal
          actionButtons={[
            {
              id: 'remove',
              content: t('remove'),
              disableAutoClose: true,
              props: { type: 'submit', loading: removing, onClick: handleRemove, level: 'warning' }
            }
          ]}
          show={showModal}
          cancelButton
          title={t('remove tournament')}
          openButton={{ hidden: true }}
          onVisibilityChange={setShowModal}
        >
          {error && <div className={styles.error}>{error.message}</div>}
          <RemoveTournamentModal timings={tourn?.registrationRestrictions} />
        </ButtonModal>
      </Panel>
    </PageMaxWidth>
  )
}

interface RemoveTournamentModalProps {
  timings?: RegistrationRestrictions
}

const RemoveTournamentModal: React.FC<RemoveTournamentModalProps> = ({ timings }) => {
  const { t } = useTranslation()
  return (
    <>
      <BodyRegular>{t('remove tournament message')}</BodyRegular>
      {timings?.entriesOpenDate && (
        <Grid container item>
          <BodyRegularBold spacing={{ margins: { md: 'top' } }}>
            {`${t('registration open')}:`}
          </BodyRegularBold>
          <BodyLarge spacing={{ margins: { md: 'top', xxs: 'left' } }}>
            {t('reg open close date', {
              date: moment(timings?.entriesOpenDateTime).tz(timings?.timeZone)
            })}
          </BodyLarge>
        </Grid>
      )}
      {timings?.entriesCloseDate && (
        <Grid container item>
          <BodyRegularBold spacing={{ margins: { md: 'top' } }}>
            {`${t('registration close')}`}
          </BodyRegularBold>
          <BodyLarge spacing={{ margins: { md: 'top', xxs: 'left' } }}>
            {t('reg open close date', {
              date: moment(timings?.entriesCloseDateTime).tz(timings?.timeZone)
            })}
          </BodyLarge>
        </Grid>
      )}
      <BodyRegular spacing={{ margins: { md: 'top' } }}>
        {t('remove tournament question')}
      </BodyRegular>
    </>
  )
}

interface RemoveTournamentPoolProps {
  registrationDates?: RegistrationDates | null
  isEmpty: boolean
}

const RemoveTournamentPoolModal: React.FC<RemoveTournamentPoolProps> = ({
  registrationDates,
  isEmpty
}) => {
  const { t } = useTranslation()
  return (
    <>
      {isEmpty ||
      (!registrationDates?.entriesOpenDateTime && !registrationDates?.entriesCloseDateTime) ? (
        <BodyLarge>{t('remove tournament group question')}</BodyLarge>
      ) : (
        <>
          <BodyRegular>{t('remove tournament group message')}</BodyRegular>
          {registrationDates?.entriesOpenDateTime && (
            <Grid container item>
              <BodyRegularBold spacing={{ margins: { md: 'top' } }}>
                {`${t('registration open')}:`}
              </BodyRegularBold>
              <BodyLarge spacing={{ margins: { md: 'top', xxs: 'left' } }}>
                {t('reg open close date', {
                  date: moment(registrationDates?.entriesOpenDateTime).tz(
                    registrationDates.timeZone
                  )
                })}
              </BodyLarge>
            </Grid>
          )}
          {registrationDates?.entriesCloseDateTime && (
            <Grid container item>
              <BodyRegularBold spacing={{ margins: { md: 'top' } }}>
                {`${t('registration close')}`}
              </BodyRegularBold>
              <BodyLarge spacing={{ margins: { md: 'top', xxs: 'left' } }}>
                {t('reg open close date', {
                  date: moment(registrationDates?.entriesCloseDateTime).tz(
                    registrationDates.timeZone
                  )
                })}
              </BodyLarge>
            </Grid>
          )}
          <BodyRegular spacing={{ margins: { md: 'top' } }}>
            {t('remove tournament group question')}
          </BodyRegular>
        </>
      )}
    </>
  )
}

interface AddTournamentModalProps {
  pool: TournamentPool
  refetchPool: () => void
}

const AddTournamentModal: React.FC<AddTournamentModalProps> = ({ pool, refetchPool }) => {
  const { t } = useTranslation()
  const orgId = useOrgId()
  const queryFilters = useMemo<PropertyFilterInput[]>(() => {
    return [
      { property: 'sanctionStatus', values: [], operator: Operator.NOT_NULL },
      { property: 'timings.startDate', values: [pool.toDate], operator: Operator.LT },
      { property: 'timings.startDate', values: [pool.fromDate], operator: Operator.GTE },
      { property: 'restrictedToLevelId', values: [pool.levelId], operator: Operator.EQUALS }
    ]
  }, [pool])

  const getTotalItems = useCallback(
    (d: GroupTournamentOptions) => d.paginatedTournaments.totalItems,
    []
  )

  const getSortProperty = useCallback(key => {
    return `${key}`
  }, [])

  const [checked, setChecked] = useState<{ [key: string]: boolean }>({})

  const { data, loading, controlProps, refetch: refetchTournaments } = useControlledQuery<
    GroupTournamentOptions,
    GroupTournamentOptionsVariables,
    Tournament
  >(GET_ADD_GROUP_TOURNAMENT_OPTIONS, {
    client: tournamentsClient,
    variables: { orgId, filters: queryFilters },
    getTotalItems,
    getSortProperty
  })

  const [error, setError] = useState<string>('')
  const [addTournamentsToPool, { loading: adding, error: addingError }] = useMutation<
    AddTournamentsToPool,
    AddTournamentsToPoolVariables
  >(ADD_TOURNAMENTS_TO_POOL, {
    client: tournamentsClient,
    onError: e =>
      setError(
        e.message.includes('ValidationError') ? t('add tournament to group error') : e.message
      )
  })

  const addTournaments = useCallback(async () => {
    const ids = Object.entries(checked).flatMap(([k, v]) => {
      if (v) return k
    })
    const res = await addTournamentsToPool({
      variables: {
        poolId: pool.id,
        tournamentIds: ids.filter(id => {
          return id !== undefined
        })
      },
      update: () => {
        refetchTournaments()
        refetchPool()
        clearTPs(tournamentsClient.cache)
      }
    })
    if (res) return res
  }, [checked, addTournamentsToPool, adding, addingError, error])

  return (
    <ButtonModal
      openButton={{
        content: t('add tournaments'),
        props: { spacing: { margins: { sm: 'bottom' } } }
      }}
      title={t('find tournaments')}
      cancelButton
      onVisibilityChange={show => {
        setError('')
        return !show && setChecked({})
      }}
      actionButtons={[
        {
          disableAutoClose: true,
          id: 'add',
          content: t('add tournaments'),
          props: {
            loading: adding,
            onClick: async setShow => {
              const res = await addTournaments()
              if (res) setShow(false)
            }
          }
        }
      ]}
    >
      <>
        <Alert spacing={{ margins: { sm: 'bottom' } }}>{t('one group tournament')}</Alert>
        <div className={styles.addTournamentModal}>
          <div>
            <AdminTable
              rowIsDisabled={r => !!r.tournamentPool}
              loading={loading}
              controls={controlProps}
              data={data?.paginatedTournaments.items}
              hideControlsOnLoading
              columns={[
                { title: t('name'), getValue: t => t.name, key: 'name' },
                {
                  title: t('start date'),
                  getValue: t => t.timings.startDate,
                  key: 'timings.startDate',
                  noWrapTitle: true
                },
                {
                  title: t('end date'),
                  getValue: t => t.timings.endDate,
                  key: 'timings.endDate',
                  noWrapTitle: true
                },
                {
                  title: t('venue'),
                  getValue: t => t.primaryLocation.name,
                  key: 'primaryLocation.name'
                },
                {
                  title: t('state'),
                  getValue: t => t.primaryLocation.county,
                  key: 'primaryLocation.county'
                },
                {
                  title: t('zip'),
                  getValue: t => t.primaryLocation.postcode,
                  key: 'primaryLocation.postcode'
                },
                {
                  key: 'checkbox',
                  title: ' ',
                  sort: false,
                  getValue: p => (
                    <Checkbox
                      checked={!!checked[p.id]}
                      onChecked={isChecked => setChecked({ ...checked, [p.id]: isChecked })}
                    />
                  )
                }
              ]}
            />
          </div>
          {error && <APIErrorMessage error={error} />}
        </div>
      </>
    </ButtonModal>
  )
}

export default TournamentGroup
