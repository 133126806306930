import { useMutation } from '@apollo/client'
import { print } from 'graphql'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputLabel from 'src/components/input-label/input-label'
import RadioButtonGroup from 'src/components/radio-button-group/radio-button-group'
import { getEnvConfig } from 'src/config/config'
import { Gender } from 'src/graphql-types/globalTournamentTypes'
import Alert from '../alert/alert'
import DatePicker from '../date-picker/date-picker'
import FloatingNotification from '../floating-notification/floating-notification'
import AutomaticDownload from '../generate-report/automatic-download'
import { OnReportUploadStateChange, ReportUploadState } from '../generate-report/generate-report'
import ButtonModal, { ButtonModalProps } from '../modal/modal'
import {
  PROCESS_TOURNAMENT_POOL_SELECTIONS,
  RESET_TOURNAMENT_POOL_SELECTIONS,
  TOURNAMENT_POOL_SELECTIONS
} from './tournament-group-selections-queries'
import * as styles from './tournament-group-selections.module.less'
import moment from 'moment-timezone'
import {
  ResetTournamentPoolSelections,
  ResetTournamentPoolSelectionsVariables
} from 'src/graphql-types/ResetTournamentPoolSelections'
import {
  ProcessTournamentPoolSelections,
  ProcessTournamentPoolSelectionsVariables
} from 'src/graphql-types/ProcessTournamentPoolSelections'
import { tournamentsClient } from 'src/apollo/client'

const DATE_SELECTION_TIMEZONE = 'America/New_York'

interface ProcessSelectionModalProps {
  setShow: ButtonModalProps['onVisibilityChange']
  showModal: ButtonModalProps['show']
  groupId?: string
}

export const DownloadCSVModal: React.FC<ProcessSelectionModalProps> = ({
  showModal,
  setShow,
  groupId
}) => {
  const { t } = useTranslation()
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none')
  const [popupClosed, setPopupClosed] = useState(false)

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    state => {
      if (state === 'processing') setPopupClosed(false)
      setReportUploadState(state)
    },
    [setReportUploadState]
  )

  const { onVisibilityChange, filter, setFilter } = useProcessSelectionFilter(setShow)
  const [downloadable, setDownloadable] = useState<boolean>(false)

  return (
    <>
      <ButtonModal
        show={showModal}
        title={t('select ranking list')}
        openButton={{ hidden: true }}
        onVisibilityChange={onVisibilityChange}
        additionalFooterContent={
          <AutomaticDownload
            reportQuery={print(TOURNAMENT_POOL_SELECTIONS)}
            reportQueryEndpoint={getEnvConfig().TOURNAMENTS_GQL_URL_KUBE}
            reportUploadState={reportUploadState}
            onReportUploadStateChange={onReportUploadStateChange}
            downloadable={downloadable}
            setDownloadable={setDownloadable}
            reportQueryVariables={{
              poolId: groupId,
              date: filter.date.toISOString(),
              gender: filter.gender
            }}
          />
        }
      >
        <ModalContent>
          <ProcessSelectionFilters setValues={setFilter} values={filter} />
        </ModalContent>
      </ButtonModal>
      {reportUploadState === 'processing' && !popupClosed && (
        <FloatingNotification
          hideCloseButton
          message={t('preparing download')}
          onClose={() => setPopupClosed(true)}
          variant="download"
        />
      )}
      {reportUploadState === 'downloadable' && !popupClosed && (
        <FloatingNotification
          message={t('report downloaded')}
          variant="downloaded"
          onClose={() => setPopupClosed(true)}
        />
      )}
    </>
  )
}

export const ProcessSelectionsModal: React.FC<ProcessSelectionModalProps> = ({
  groupId,
  setShow,
  showModal
}) => {
  const { t } = useTranslation()
  const { filter, setFilter } = useProcessSelectionFilter(setShow)
  const [processSelections, { loading, data }] = useMutation<
    ProcessTournamentPoolSelections,
    ProcessTournamentPoolSelectionsVariables
  >(PROCESS_TOURNAMENT_POOL_SELECTIONS, {
    client: tournamentsClient,
    variables: {
      poolId: groupId,
      date: moment(filter.date)
        .tz(DATE_SELECTION_TIMEZONE)
        .endOf('day')
        .toISOString(),
      gender: filter.gender
    }
  })
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (data) {
      setShow?.(false)
      setShowPopup(true)
    }
  }, [data])

  return (
    <>
      {showPopup && (
        <FloatingNotification
          message={t('selections processed')}
          variant="downloaded"
          onClose={() => setShowPopup(false)}
        />
      )}
      <ButtonModal
        actionButtons={[
          {
            id: 'process-selections',
            content: t('process selections'),
            props: { onClick: () => processSelections(), loading }
          }
        ]}
        show={showModal}
        title={t('process registration')}
        openButton={{ hidden: true }}
        onVisibilityChange={setShow}
      >
        <ModalContent>
          <ProcessSelectionFilters values={filter} setValues={setFilter} />
          <Alert spacing={{ margins: { md: 'top' } }}>{t('check csv correct prompt')}</Alert>
        </ModalContent>
      </ButtonModal>
    </>
  )
}

export const ResetSelectionsModal: React.FC<ProcessSelectionModalProps> = ({
  setShow,
  showModal,
  groupId
}) => {
  const { t } = useTranslation()
  const [resetSelections, { loading, data }] = useMutation<
    ResetTournamentPoolSelections,
    ResetTournamentPoolSelectionsVariables
  >(RESET_TOURNAMENT_POOL_SELECTIONS, {
    client: tournamentsClient,
    variables: { poolId: groupId }
  })
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (data) {
      setShow?.(false)
      setShowPopup(true)
    }
  }, [data, setShow, setShowPopup])

  return (
    <>
      {showPopup && (
        <FloatingNotification
          message={t('selections reset')}
          variant="downloaded"
          onClose={() => setShowPopup(false)}
        />
      )}
      <ButtonModal
        actionButtons={[
          {
            id: 'reset-selections',
            content: t('reset selections'),
            props: { onClick: () => resetSelections(), loading }
          }
        ]}
        show={showModal}
        title={t('reset selections')}
        openButton={{ hidden: true }}
        onVisibilityChange={setShow}
      >
        <ModalContent>
          <Alert spacing={{ margins: { md: 'top' } }}>{t('reset selections warning')}</Alert>
        </ModalContent>
      </ButtonModal>
    </>
  )
}

interface ProcessSelectionFilter {
  gender: Gender
  date: Date
}

interface ProcessSelectionFiltersProps {
  values: ProcessSelectionFilter
  setValues: (values: ProcessSelectionFilter) => void
}

const ProcessSelectionFilters: React.FC<ProcessSelectionFiltersProps> = ({ values, setValues }) => {
  const { t } = useTranslation()
  const setGender = useCallback((gender: Gender) => setValues({ ...values, gender }), [
    setValues,
    values
  ])
  const setDate = useCallback((date: Date) => setValues({ ...values, date }), [setValues, values])
  const { gender, date } = values

  return (
    <>
      <InputLabel spacing={{ margins: { xs: 'bottom' } }}>{t('gender')}</InputLabel>
      <RadioButtonGroup
        label={t('gender')}
        options={[
          { label: t('female'), value: Gender.GIRLS },
          { label: t('male'), value: Gender.BOYS }
        ]}
        handleChange={c => setGender(c.target.value as Gender)}
        value={gender}
      />

      <InputLabel spacing={{ margins: { md: 'top', xs: 'bottom' } }}>{t('select date')}</InputLabel>

      <DatePicker onChange={setDate} selected={date} />
    </>
  )
}

const ModalContent: React.FC = ({ children }) => {
  return <div className={styles.modalWidth}>{children}</div>
}

const useProcessSelectionFilter = (setShow: ButtonModalProps['onVisibilityChange']) => {
  const easternTime = useCallback(() => {
    return moment()
      .tz(DATE_SELECTION_TIMEZONE)
      .toDate()
  }, [])

  const [filter, setFilter] = useState<ProcessSelectionFilter>({
    gender: Gender.GIRLS,
    date: easternTime()
  })

  const onVisibilityChange = useCallback(
    show => {
      if (!show) {
        // reset our filter
        setFilter({ gender: Gender.GIRLS, date: easternTime() })
      }
      setShow?.(show)
    },
    [setShow, setFilter]
  )

  return { onVisibilityChange, filter, setFilter }
}
